
import React, {useState,useRef, useEffect} from "react"
import ColourSelectorPanel from "./colourSelector/colourSelectorPanel";
import { exportComponentAsJPEG } from 'react-component-export-image';
import ColourSelector from "./colourSelector/colourSelector";
import Randomize from "./colourSelector/randomize";
import Logo from "../Logo.svg"


const ComponentToPrint = React.forwardRef((props, componentRef) => {
    const {colourPalette, dispatch, page, isDownloadView} = props.state

    return (
        <div ref={componentRef} fclassName="m-4 is-inline" >
            <div className="columns is-vcentered">
                <div className={
                    `column
                    debug
                    is-flex
                    is-flex-wrap-wrap
                    is-justify-content-space-around
                    is-align-content-space-around
                    is-fullwidth`
                }
                >
                    {colourPalette.map(colour => {
                        if(colour.active || isDownloadView) {
                            return <ColourSelector
                                state={
                                    {
                                        colour, dispatch, colourPalette, page, isDownloadView}
                             }/>
                         }
                        return <> </>;
                    })}
                </div>
            </div>
            <div className="m-1 is-flex is-justify-content-space-between">
                <div className="m-2">
                   <img height="10px" width="auto" src={Logo}/>
                </div>
                <div className="m-2">
                    <p style={
                        {
                            fontFamily:"inter"
                        }
                    }>abha.page</p>
                </div>
            </div>
        </div>)
});

function NextNavigator(props) {
    const {page, changePage, colourPalette} = props.state

   let [isModal, setIsModal] = useState(false)
    let [scroll_style_element, setscroll_style_element] = useState()


    const componentRef = useRef();

    function disable_scrolling(){
        window.scrollTo(0, 0);
        // Create a style sheet we will only use to disable scrolling :
        let scroll_style_element = document.createElement('style');
        document.head.appendChild(scroll_style_element);
        const scroll_style_sheet = scroll_style_element.sheet;

        scroll_style_sheet.insertRule('html{height:100%;overflow-y:hidden;}', scroll_style_sheet.cssRules.length);
        setscroll_style_element(
           scroll_style_element
        )
    }

    function enable_scrolling(){
        if(scroll_style_element) {
            document.head.removeChild(scroll_style_element);
            setscroll_style_element({})
        }

    }

    useEffect(() => {
        if (isModal) disable_scrolling()
        else {
            enable_scrolling()
        }
    }, [isModal]);

    function handleClick() {
        setIsModal(!isModal)
    }

    function incrementPage() {
        changePage((prevPage) => {
            return prevPage+1
        })
    }
    function decrementPage() {
        changePage((prevPage) => {
            return prevPage-1
        })
    }
    let active = isModal ? "is-active" : ""
    let isDownloadView = true
    function dispatch() {}
    return (
          <>
            <div>
                <div className={`modal  is-large ${active}`}>
                    <div className="modal-background "/>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <button
                                onClick={handleClick}
                                className="delete"
                                aria-label="close"
                            />
                        </header>
                        <section className="is-large modal-card-body">
                            <ComponentToPrint  ref={componentRef} state={{colourPalette, dispatch, page, isDownloadView}} />
                        </section>
                        <footer className="modal-card-foot is-flex is-justify-content-space-around">

                            <a className="button" target="_blank" href="https://abha.framer.website/" style={{
                                fontFamily:"inter"
                            }}>
                                Get Custom made Palette!
                            </a>
                            <button className="button is-link" onClick={ () => {
                                let fileName = "abha-colour-palette.jpg"
                                return exportComponentAsJPEG(componentRef, {fileName})
                            }
                            }>Download JPG </button>
                        </footer>
                    </div>
                </div>
            </div>
        <div className="next-box">
            <div className="columns is-gapless">
                <div className="column">
                    <div className="is-flex  is-flex-direction-column is-align-items-flex-start is-justify-content-space-between">
                        {
                            page == 1 &&
                            <>
                                <div className="title-one flex-item">
                                    Choose primary colors that represent your brand 😍
                                </div>
                                <span className="is-mobile-only mt-2">
                                    <br />


                                </span>
                                <div className="title-two flex-item">
                                    Randomise colors, select custom colors, lock if you like it. Click next for remaining colors!
                                </div>
                            </>
                        }
                        {
                            page == 2 &&
                            <>
                                <div className="title-one flex-item">
                                   Add more depth with text and accent colors 😊
                                </div>
                                <span className="is-mobile-only mt-2">
                                    <br />

                                </span>
                                <div className="title-two flex-item">
                                    Same rules as before. Head back to primary colors or download the palette!
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="column is-one-quarter is-flex is-justify-content-space-around">
                    {
                        page !== 1 &&
                        <div className="column">
                                <button onClick={decrementPage}  className="button is-link is-fullwidth">
                                    <span className="icon is-medium">
                                        <i className="fa fa-angle-double-left"></i>
                                        <span>&nbsp;Back</span>
                                    </span>
                                </button>
                        </div>
                    }
                    {
                        page !== 2 &&
                        <div className="column">
                            <button onClick={incrementPage} className="button is-link is-fullwidth">
                            <span className="icon is-medium">
                                  <span>Next&nbsp;</span>
                                <i className="fa fa-angle-double-right"></i>
                            </span>
                            </button>
                        </div>
                    }
                    <div className="column">
                        <button onClick={handleClick} className="button is-fullwidth">
                            <span className="icon is-medium">
                                  <span>Download&nbsp;</span>
                                <i className="fa fa-download"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default NextNavigator;