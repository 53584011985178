
export const ColourTypes = {
    PRIMARY_LIGHT: "Primary Light",
    PRIMARY: "Primary",
    PRIMARY_DARK: "Primary Dark",
    DARK_ON_TEXT: "text on dark",
    TEXT_600: "Text 600",
    TEXT_900: "Text 900",
    WARNING: "Warning",
    SUCCESS: "Success",
    FAILURE: "Failure"
}

export const PageToColourMap = {
    // 1: [
    //     ColourTypes.PRIMARY
    // ],
    1: [
        ColourTypes.PRIMARY_LIGHT,
        ColourTypes.PRIMARY,
        ColourTypes.PRIMARY_DARK
    ],
    2:[
        ColourTypes.DARK_ON_TEXT,
        ColourTypes.TEXT_600,
        ColourTypes.TEXT_900,
        ColourTypes.WARNING,
        ColourTypes.SUCCESS,
        ColourTypes.FAILURE
    ]
}


let ColourPalette = () => {
    let colourPaletteState = []
    for(let colour in ColourTypes){
        colourPaletteState.push(
            {
                name: ColourTypes[colour],
                active: false,
                locked: false,
                colour: "#aaaaaa"
            }
        )
    }

    return colourPaletteState;
}

export default ColourPalette;