import {ACTIONS} from "./colourPaletteReducer";
import React from  "react"

function Randomize({props}) {

   let {dispatch, colourPalette} = props
   function randomize() {
       dispatch({
               type: ACTIONS.RANDOMIZE,
               state: colourPalette
           }
       )
   }

   return(<button onClick={randomize} className="button is-link">
                            <span className="icon is-medium">
                                <i className="fas fa-random"></i>
                            </span>
        <span>Randomize Colours</span>
    </button>)
}

export default Randomize;