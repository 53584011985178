import colourPalette, {ColourTypes, PageToColourMap} from "./colourPalette"
import Randomize from "./randomize";
import tinycolor from "tinycolor2";

export const ACTIONS = {
    PAGE_CHANGE: "page change",
    RANDOMIZE: "randomize colours",
    TOGGLE_LOCK: "toggle lock",
    EDIT_COLOUR: "edit colour",
    FORCED_RANDOMIZE: "forcefully randomize all colours"
}

function checkIfLockRequired(locked, page, colourType) {

    // if (colourType == ColourTypes.PRIMARY && page == 2) {
    //     return true
    // }

    return locked
}

function handlePageChange(state, payload) {
    return state.map((val) => {
       if(PageToColourMap[payload.page].includes(val.name)){
           return {
               ...val,
               active: true,
               locked:  checkIfLockRequired(val.locked, payload.page, val.name)
           }
       } else {
          return {
              ...val,
              active: false,
              locked: checkIfLockRequired(val.locked, payload.page, val.name)
          }
       }
    })
}

function getColourOfType(type, colours) {
    //console.log(colours)
    for(let i=0; i<colours.length; i++) {
        if(colours[i].name == type) {
            return colours[i].colour
        }
    }
}

function randomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getPrimaryLightColour(primaryColour) {
    let primaryColourHsv = tinycolor(primaryColour).toHsv()
    let primaryLightHsv = primaryColourHsv
    primaryLightHsv.s = randomRange(5, 16)
    primaryLightHsv.v = randomRange(90, 99)
    return "#"+ tinycolor(primaryLightHsv).toHex()
}

function getPrimaryDarkColour(primaryColour) {
    let primaryColourHsv = tinycolor(primaryColour).toHsv()
    let primaryDarkHsv = primaryColourHsv
    primaryDarkHsv.v = ( primaryColourHsv.v * randomRange(35, 70))/ 100
    return "#"+ tinycolor(primaryDarkHsv).toHex()
}

function getTextOnDarkColour(primaryColour) {
    let primaryColourHsv = tinycolor(primaryColour).toHsv()
    let updatedColour = primaryColourHsv;
    updatedColour.v = ( primaryColourHsv.v * randomRange(90, 99))/ 100
    updatedColour.s = ( primaryColourHsv.s * randomRange(0, 4))/ 100
    return "#"+ tinycolor(updatedColour).toHex()
}

function getText600Colour(primaryColour) {
    let primaryColourHsv = tinycolor(primaryColour).toHsv()
    let updatedColour = primaryColourHsv;
    updatedColour.v = ( primaryColourHsv.v * randomRange(20, 30))/ 100
    updatedColour.s = ( primaryColourHsv.s * randomRange(0, 10))/ 100
    return "#"+ tinycolor(updatedColour).toHex()
}

function getText900Colour(primaryColour) {
    let primaryColourHsv = tinycolor(primaryColour).toHsv()
    let updatedColour = primaryColourHsv;
    updatedColour.v = ( primaryColourHsv.v * randomRange(5, 15))/ 100
    updatedColour.s = ( primaryColourHsv.s * randomRange(0, 10))/ 100
    return "#"+ tinycolor(updatedColour).toHex()
}

function getWarningColour(primaryColour) {
    let warningColour = tinycolor.random().toHsv()
    /*
        orange
        h 18-28
        S greater than 80
        B- greater than 90
     */
    warningColour.h = randomRange(18, 28)
    warningColour.v = randomRange(80, 100)
    warningColour.s = randomRange(90,100)
    return "#"+ tinycolor(warningColour).toHex()
}

function getSuccessColour(primaryColour) {
    let successColour = tinycolor.random().toHsv()
    /*
      Green
        H 135-150
        S greater than 80
        B greater than 90
     */
    successColour.h = randomRange(135, 145)
    successColour.v = randomRange(65, 75)
    successColour.s = randomRange(90,100)
    return "#"+ tinycolor(successColour).toHex()
}

function getFailureColour(primaryColour) {
    let failureColour = tinycolor.random().toHsv()
    /*
      red
        h less than 10
        S greater than 80
        B- greater than 90
     */
    failureColour.h = randomRange(0, 10)
    failureColour.v = randomRange(80, 100)
    failureColour.s = randomRange(90,100)
    return "#"+ tinycolor(failureColour).toHex()
}

function randomColourForType(type, state) {
    let colour = "#" + tinycolor.random().toHex()

    switch (type) {
        case ColourTypes.PRIMARY:
            return colour;
        case ColourTypes.PRIMARY_LIGHT:
             return getPrimaryLightColour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.PRIMARY_DARK:
            return getPrimaryDarkColour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.DARK_ON_TEXT:
            return getTextOnDarkColour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.TEXT_600:
            return getText600Colour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.TEXT_900:
            return getText900Colour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.WARNING:
            return getWarningColour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.SUCCESS:
            return getSuccessColour(getColourOfType(ColourTypes.PRIMARY, state))
        case ColourTypes.FAILURE:
            return getFailureColour(getColourOfType(ColourTypes.PRIMARY, state))
        default:
            return "#FFFFFF"
    }
}

function randomize(col, state, isForced) {
    if(!isForced && (!col.active || col.locked)){
        return col
    } else {
        return {
            ...col,
            colour: randomColourForType(col.name, state)
        }
    }
}

function handleForcedRandomizeAll(state) {
    let randomPrimaryState =  state.map((val) => {
        if(val.name != ColourTypes.PRIMARY) {
            return val;
        }
        return randomize(val, state, true)
    })

    return randomPrimaryState.map((val) => {
        if(val.name == ColourTypes.PRIMARY) {
            return val;
        }
        return randomize(val, randomPrimaryState, true)
    })
}

function handleRandomizeColours(state){
    let randomPrimaryState =  state.map((val) => {
        if(val.name != ColourTypes.PRIMARY) {
            return val;
        }
        return randomize(val, state, false)
    })

    return randomPrimaryState.map((val) => {
        if(val.name == ColourTypes.PRIMARY) {
            return val;
        }
        return randomize(val, randomPrimaryState, false)
    })
}

function toggleLock(val, payload) {
        return {
        ...val,
            locked: !val.locked
        }

}

function handleLockToggle(state, payload) {
    return state.map((val) => {
        if(payload.name == val. name)
         return toggleLock(val, payload)
        else return val
    })
}

function editColour(val, payload) {
    return {
        ...val,
        colour: payload.color
    }

}

function handleEditColour(state, payload) {
    return state.map((val) => {
        if(payload.name == val. name)
            return editColour(val, payload)
        else return val
    })
}

export default function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.PAGE_CHANGE:
            return handlePageChange(state, action.payload)
        case ACTIONS.RANDOMIZE:
            return handleRandomizeColours(state)
        case ACTIONS.FORCED_RANDOMIZE:
            return handleForcedRandomizeAll(state)
        case ACTIONS.TOGGLE_LOCK:
            return handleLockToggle(state, action.payload)
        case ACTIONS.EDIT_COLOUR:
            return handleEditColour(state, action.payload)
        default:
            return state
    }
}

