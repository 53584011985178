import Logo from "../newLogo.gif"
import React from  "react"


function Navbar() {
     return (
            <nav className='navbar has-shadow' role='navigation'>
                <div className="container navbar-content is-flex is-justify-content-space-between">
                    <div className='navbar-brand'>
                        <a href='/' className='navbar-item'>
                            <img
                                src={Logo}
                                alt='Logo'
                                width='104'
                                height='24'
                            />
                        </a>
                    </div>

                        <div className="navbar-item">
                            <a className="mt-2" href="https://www.producthunt.com/posts/abha-page?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-abha-page" target="_blank"  >
                                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383799&theme=light" alt="abha.page - Generate elegant color palette & visualize with live website | Product Hunt"  width="150px" height="30px"/>
                            </a>
                            <div className="buttons">
                                <a className="button is-small is-link"  target="_blank" href="https://abha.framer.website/" style={{
                                    fontFamily:"inter"
                                }}>
                                    Get Custom made Palette!
                                </a>
                            </div>
                        </div>
                    </div>
            </nav>

    );
}

export default Navbar;