import ColourSelector from "./colourSelector";
import Randomize from "./randomize";
import React from  "react"
import {ACTIONS} from "./colourPaletteReducer";

function ColourSelectorPanel({state}) {

    const {colourPalette, dispatch, page, isDownloadView} = state

    let addClassesForNotDownload = isDownloadView ? "" : " is-9"
    return (
        <div className="m-4 is-inline" >
            <div className="columns is-vcentered">
                <div className={
                    `column
                    debug
                    is-flex
                    is-flex-wrap-wrap
                    is-justify-content-space-around
                    is-align-content-space-around
                    is-fullwidth`
                    + addClassesForNotDownload
                }
                >
                    {colourPalette.map(colour => {
                        if(colour.active || isDownloadView) {
                            return <ColourSelector
                                state={
                                {colour, dispatch, colourPalette, page, isDownloadView}}/>
                        }

                        return <> </>;
                    })}
                </div>

                {!isDownloadView && <div className="column debug">
                    <Randomize props={{dispatch, colourPalette}}/>
                </div> }

            </div>
        </div>
        );
}


export default ColourSelectorPanel;