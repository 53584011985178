import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {ACTIONS} from "./colourPaletteReducer";
import {ColourTypes, PageToColourMap} from "./colourPalette"
import {useState} from "react";
import React from  "react"
import { SketchPicker } from 'react-color';


const MaterialUISwitch = styled(Switch)(({theme}) => ({
    width: 62, height: 34, padding: 7, '& .MuiSwitch-switchBase': {
        margin: 1, padding: 0, transform: 'translateX(6px)', '&.Mui-checked': {
            color: '#fff', transform: 'translateX(22px)', '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 25 25"><path fill="${encodeURIComponent('#fff',)}" d="M17,9.99V7A5,5,0,0,0,7,7V9.99A4.482,4.482,0,0,0,4.5,14v3A4.507,4.507,0,0,0,9,21.5h6A4.507,4.507,0,0,0,19.5,17V14A4.482,4.482,0,0,0,17,9.99ZM13,16a1,1,0,0,1-2,0V15a1,1,0,0,1,2,0Zm2-6.5H9V7a3,3,0,0,1,6,0Z"/></svg>')`,
            }, '& + .MuiSwitch-track': {
                opacity: 1, backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    }, '& .MuiSwitch-thumb': {
        backgroundColor: "#000000", width: 32, height: 32, '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 25 25"><path fill="${encodeURIComponent('#fff',)}" d="M15,9.5H9V7a3.009,3.009,0,0,1,3-3,3.162,3.162,0,0,1,3.03,2.25,1,1,0,0,0,1.94-.5A5.15,5.15,0,0,0,12,2,5,5,0,0,0,7,7V9.99A4.482,4.482,0,0,0,4.5,14v3A4.507,4.507,0,0,0,9,21.5h6A4.507,4.507,0,0,0,19.5,17V14A4.507,4.507,0,0,0,15,9.5ZM13,16a1,1,0,0,1-2,0V15a1,1,0,0,1,2,0Z"/></svg>')`,
        },
    }, '& .MuiSwitch-track': {
        opacity: 1, backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be', borderRadius: 20 / 2,
    },
}));

function ColourSelector({state}) {
    let {name, colour, locked} = state.colour
    let dispatch = state.dispatch
    let isDownloadView = state.isDownloadView
    const [showPopup, setShowPopup] = useState(false);

    let handleLockChange = function () {
        dispatch({
            type: ACTIONS.TOGGLE_LOCK, state: state.colourPalette, payload: {
                name: name
            }
        });
    };

    let handleChangeComplete = (color) => {
        dispatch({
            type: ACTIONS.EDIT_COLOUR, state: state.colourPalette, payload: {
                name: name,
                color: color.hex,
            }
        });
    };

    let disabled = false
    // if(name == ColourTypes.PRIMARY && state.page == 2) {
    //     disabled = true
    // }

    let colorBoxClassName = isDownloadView? "colour-box-download" : "colour-box"

    return (<span className={ colorBoxClassName + " column"}
                  style={{
                      backgroundColor: colour
                  }}>


                {!isDownloadView &&

                    <div className="action-buttons debug">
                             <span>
                                 <IconButton onClick={() => setShowPopup(!showPopup)}
                                             aria-label="edit"
                                             ize="large">

                                     <EditIcon/>

                                 </IconButton>
                                 {
                                     showPopup && (
                                         <div className="popup">
                                             <button className="close-btn"
                                                     onClick={() => setShowPopup(false)}>X
                                             </button>
                                             <SketchPicker
                                                 color={colour}
                                                 onChangeComplete={handleChangeComplete}
                                             />
                                         </div>
                                     )
                                 }
                                 <FormControlLabel
                                     control={<
                                         MaterialUISwitch sx={{m: 1}}
                                                          onChange={handleLockChange}
                                                          checked={locked}
                                                          disabled={disabled}
                                     />}
                                     disabled={disabled}
                                 />
                                 </span>
                    </div>

                }

                        <div className="
                            colour-text
                            debug
                            "
                             style={{
                                 color: pickTextColorBasedOnBgColorAdvanced(colour)
                             }}
                        >
                                <span className="has-text-weight-bold">
                                  {colour.toUpperCase()}
                                </span>
                                <span>
                                    {name}
                                </span>
                        </div>

                </span>);
}

function pickTextColorBasedOnBgColorAdvanced(bgColor) {
    let lightColor = "#FFFFFF"
    let darkColor = "#000000"
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
}

export default ColourSelector;